import React, { Suspense, lazy, useEffect, useState } from "react";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
// import Phase4Gallery from "../components/Onboarding/Phase 4/Phase4Gallery";
// import { Login } from "../components/Login";

// import { Home } from "../pages/Home";

// import UserLevelRestriction from "../pages/Profile/UserLevelRestriction/UserLevelRestriction";
// import { LeaveApplied } from "../pages/Tables/LeaveApplied";
// import { TotalEmployees } from "../pages/Tables/TotalEmployees";
// import { Travel_Orders } from "../pages/Tables/Travel_Orders";
// import ListOfUser from "../pages/Profile/ListOfUser/ListOfUser";

// import { EmailVerification } from "../components/EmailVerification";

// import Profile from "../components/ApplicationForms/Profile";

// import { Dashboard } from "../pages/Dashboard";

// import ChangePassword from "../components/ChangePassword/ChangePassword";

// import NewVacantPosition from "../components/VacantPosition/NewVacantPosition";
// import JobDescription from "../components/VacantPosition/JobDescription";
// import NewAppliedPosition from "../components/AppliedPositions/NewAppliedPosition";
// import AppliedJobDescription from "../components/AppliedPositions/AppliedJobDescription";
// import ArchivedPositions from "../components/ArchivedPositions/ArchivedPositions";
// import AddJobPosition from "../pages/AddJobPosition/AddJobPosition";
// import Form from "../pages/AddJobPosition/Form";
// import EditJobPosition from "../pages/AddJobPosition/EditJobPosition";

// import ApplicantDetail from "../pages/AddJobPosition/ApplicantDetail";
// import ApplicantsTable from "../pages/AddJobPosition/ApplicantsTable";
// import PreQualification from "../pages/PreQualification/PrequalificationTable";
// import NewForgotPassword from "../components/ForgotPassword/NewForgotPassword";
// import NewResetPassword from "../components/ForgotPassword/NewResetPassword";

// import NewSignupVerification from "../components/Signup/NewSignupVerification";
// import NewSignup from "../components/Signup/NewSignup";
// import AddRoles from "../components/Sections/AddRoles";
// import AddUser from "../pages/Profile/ListOfUser/AddUser";
// import EditUser from "../pages/Profile/ListOfUser/EditUser";
// import EditRoles from "../pages/Profile/UserLevelRestriction/EditRoles";
// import ListOfRoles from "../pages/ListOfRoles/ListOfRoles";
// import SavedProfile from "../components/SavedProfile/SavedProfile";
// import ArchivedJobDescription from "../components/ArchivedPositions/ArchivedJobDescription";
// import Trash from "../Trash/Trash";
// import AccountSetting from "../components/AccountSetting/AccountSetting";
// import ComposeEmail from "../components/ComposeEmail/ComposeEmail";
// import PrequaStage2 from "../pages/PreQualification/PrequaStage2";
// import PrequaStage3 from "../pages/PreQualification/PrequaStage3";
// import PrequaStage2Table from "../pages/PreQualification/PrequaStage2Table";
// import HiringDashboard from "../pages/Dashboard/HiringDashboard";
// import FinalSelection from "../pages/PreQualification/FinalSelection";
// import QualifiedScoring from "../components/QualifiedScoring/QualifiedScoring";
// import DefaultLayout from "../layout/DefaultLayout";
// import QualifiedApplicantsTable from "../components/Tables/QualifiedApplicants/QualifiedApplicantsTable";
// import WrongPage from "../components/WrongPage";
// import PrequaStage2Template from "../pages/PreQualification/PrequaStage2Template";
// import QualifiedScoringTemplate from "../components/QualifiedScoring/QualifiedScoringTemplate";
// import Phase1 from "../components/Onboarding/Phase 1/Phase1";
// import Phase2 from "../components/Onboarding/Phase 2/Phase2";
// import Phase3 from "../components/Onboarding/Phase 3/Phase3";
// import Phase4 from "../components/Onboarding/Phase 4/Phase4";
// import Phase5 from "../components/Onboarding/Phase 5/Phase5";
// import Phase6 from "../components/Onboarding/Phase 6/Phase6";
// import EmployeesToOnboard from "../components/Onboarding/EmployeesForOnboarding/EmployeesToOnboard";
// import OnboardingEmployees from "../components/Onboarding/EmployeesForOnboarding/OnboardingEmployees";
// import ListOfAwards from "../pages/R & R/ListOfAwards";
// import AddNewAward from "../pages/R & R/AddNewAward";
// import EditAward from "../pages/R & R/EditAward";
// import AwardingSchedule from "../pages/R & R/AwardingSchedule";
// import AddNominees from "../pages/R & R/AddNominees";
// import AddJudges from "../pages/R & R/AddJudges";
// import ViewAwards from "../pages/R & R/ViewAwards";
// import ListOFNominees from "../pages/R & R/ListOFNominees";
// import ListOfJudges from "../pages/R & R/ListOfJudges";
// import EditAwardForEvent from "../pages/R & R/EditAwardForEvent";
// import ListOfAllJudges from "../pages/R & R/ListOfAllJudges";
// import AddNewJudges from "../pages/R & R/AddNewJudges";

// import AddOfficeNominees from "../pages/R & R/AddOfficeNominees";
// import ListOfOfficeNominees from "../pages/R & R/ListOfOfficeNominees";
// import AwardsToJudges from "../pages/R & R/AwardsToJudges";
// import ListForScoreNominees from "../pages/R & R/ListForScoreNominees";
// import ListForScoreOffice from "../pages/R & R/ListForScoreOffice";
// import ScoreNominee from "../pages/R & R/ScoreNominee";
// import SummaryOfScores from "../pages/R & R/SummaryOfScores";
// import ScorePerJudge from "../pages/R & R/ScorePerJudge";
// import ScorePerCriteria from "../pages/R & R/ScorePerCriteria";
// import SummaryPerJudge from "../pages/R & R/SummaryPerJudge";
// import ScoreForEachJudge from "../pages/R & R/ScoreForEachJudge";

// import ListOfLeaveApplied from "../pages/Leave Applied/ListOfLeaveApplied";
// import ListOfTravelOrder from "../pages/Travel Order/ListOfTravelOrder";

// import Phase1Gallery from "../components/Onboarding/Phase 1/Phase1Gallery";
// import Phase2Gallery from "../components/Onboarding/Phase 2/Phase2Gallery";
// import Phase2Orientation from "../components/Onboarding/Phase 2/Phase2Orientation";
// import Phase3Journal from "../components/Onboarding/Phase 3/Phase3Journal";
// import Phase3Gallery from "../components/Onboarding/Phase 3/Phase3Gallery";
// import Phase3Report from "../components/Onboarding/Phase 3/Phase3Report";
// import Phase4CoachingSession from "../components/Onboarding/Phase 4/Phase4CoachingSession";
// import Phase4CoachingGallery from "../components/Onboarding/Phase 4/Phase4CoachingGallery";
// import Phase4MentorshipSession from "../components/Onboarding/Phase 4/Phase4MentorshipSession";
// import Phase5FomalDocument from "../components/Onboarding/Phase 5/Phase5FormalDocument";
// import Phase5InfomalDocument from "../components/Onboarding/Phase 5/Phase5InformalDocument";
// import Phase6Onboarding from "../components/Onboarding/Phase 6/Phase6Onboarding";
// import Phase6Gallery from "../components/Onboarding/Phase 6/Phase6Gallery";
// import ListOfPaySlip from "../pages/Pay Slip/ListOfPaySlip";
// import Mail from "../pages/Mail/Mail";
// import ListOfScoreTemplates from "../pages/AddJobPosition/ListOfScoreTemplates";
// import AddStage2Template from "../pages/AddJobPosition/AddStage2Template";
// import EditStage2Template from "../pages/AddJobPosition/EditStage2Template";
// import AddConsolidateScoringTemplate from "../pages/AddJobPosition/AddConsolidateScoringTemplate";
// import EditConsolidatedScoring from "../pages/AddJobPosition/EditConsolidatedScoring";
// import OnboardingSetup from "../components/Onboarding/Setup/OnboardingSetup";
// import ArchivedMessages from "../pages/Mail/ArchivedMessages";
// import PageUnderConstruction from "../components/PageUnderConstruction";
// import Phase6IDP from "../components/Onboarding/Phase 6/Phase6IDP";
// import Onboarding from "../pages/Onboarding/Onboarding";

// import Phase1Copy from "../components/Onboarding/Phase 1 copy/Phase1";
// import Phase2Copy from "../components/Onboarding/Phase 2 copy/Phase2";
// import Phase3Copy from "../components/Onboarding/Phase 3 copy/Phase3";
// import Phase4Copy from "../components/Onboarding/Phase 4 copy/Phase4";
// import Phase5Copy from "../components/Onboarding/Phase 5 copy/Phase5";
// import Phase6Copy from "../components/Onboarding/Phase 6 copy/Phase6";

// import Phase1GalleryCopy from "../components/Onboarding/Phase 1 copy/Phase1Gallery";
// import Phase2GalleryCopy from "../components/Onboarding/Phase 2 copy/Phase2Gallery";
// import Phase2OrientationCopy from "../components/Onboarding/Phase 2 copy/Phase2Orientation";
// import Phase3JournalCopy from "../components/Onboarding/Phase 3 copy/Phase3Journal";
// import Phase3GalleryCopy from "../components/Onboarding/Phase 3 copy/Phase3Gallery";
// import Phase3ReportCopy from "../components/Onboarding/Phase 3 copy/Phase3Report";
// import Phase4CoachingSessionCopy from "../components/Onboarding/Phase 4 copy/Phase4CoachingSession";
// import Phase4MentorshipSessionCopy from "../components/Onboarding/Phase 4 copy/Phase4MentorshipSession";
// import Phase5FomalDocumentCopy from "../components/Onboarding/Phase 5 copy/Phase5FormalDocument";
// import Phase5InfomalDocumentCopy from "../components/Onboarding/Phase 5 copy/Phase5InformalDocument";
// import Phase6OnboardingCopy from "../components/Onboarding/Phase 6 copy/Phase6Onboarding";
// import Phase6IDPCopy from "../components/Onboarding/Phase 6 copy/Phase6IDP";
// import Phase6GalleryCopy from "../components/Onboarding/Phase 6 copy/Phase6Gallery";
// import CBP_TableCopy from "../components/Onboarding/CompetencyBasedProfile copy/CBP_Table";
// import CBP_Table2Copy from "../components/Onboarding/CompetencyBasedProfile copy/CBP_Table2";
// import CBP_Table3Copy from "../components/Onboarding/CompetencyBasedProfile copy/CBP_Table3";
// import OOR from "../components/Onboarding/OverallOnboardingReport copy/OOR";
// import Phase4CoachingSessionCopy2 from "../components/Onboarding/Phase 4 copy 2/Phase4CoachingSession";
// import Phase4MentorshipSessionCopy2 from "../components/Onboarding/Phase 4 copy 2/Phase4MentorshipSession";
// import CoachingMentorship from "../components/Onboarding/EmployeesForOnboarding/CoachingMentorship";
// import ListOfDepartments from "../pages/ListOfDepartments/ListOfDepartments";
// import PsychosocialCriterias from "../components/Onboarding/Setup/PsychosocialCriterias";
// import RaterForPositions from "../components/Onboarding/Setup/RaterForPositions";
// import HRMPSBTable from "../pages/HRMPSB/HRMPSBTable";
// import ApplicantsForHRMPSB from "../pages/HRMPSB/ApplicantsForHRMPSB";
// import HRMPSBScoringRoot from "../pages/HRMPSB/HRMPSBScoringRoot";
// import BIForms from "../pages/RSP/BIForms";
// import BIReport from "../pages/RSP/BIReport";
// import BIResults from "../pages/RSP/BIResults";
// import PTForApplicants from "../pages/RSP/PTForApplicants";
// import PTResults from "../pages/RSP/PTResults";
// import Simulations from "../pages/RSP/Simulations";
// import SimulationResults from "../pages/RSP/SimulationResults";
// import EditBIForms from "../pages/RSP/EditBIForms";
// import EditBIReport from "../pages/RSP/EditBIReport";
// import EditSimulations from "../pages/RSP/EditSimulations";

const Dashboard = lazy(() => import("../pages/Dashboard/Dashboard"));
const HiringDashboard = lazy(() =>
  import("../pages/Dashboard/HiringDashboard")
);
const Profile = lazy(() => import("../components/ApplicationForms/Profile"));
const SavedProfile = lazy(() =>
  import("../components/SavedProfile/SavedProfile")
);
const Login = lazy(() => import("../components/Login/Login"));
const NewForgotPassword = lazy(() =>
  import("../components/ForgotPassword/NewForgotPassword")
);
const NewResetPassword = lazy(() =>
  import("../components/ForgotPassword/NewResetPassword")
);
const NewSignupVerification = lazy(() =>
  import("../components/Signup/NewSignupVerification")
);
const NewSignup = lazy(() => import("../components/Signup/NewSignup"));
const Home = lazy(() => import("../pages/Home/Home"));
const AccountSetting = lazy(() =>
  import("../components/AccountSetting/AccountSetting")
);
const PageUnderConstruction = lazy(() =>
  import("../components/PageUnderConstruction")
);
const WrongPage = lazy(() => import("../components/WrongPage"));
const EmailVerification = lazy(() =>
  import("../components/EmailVerification/EmailVerification")
);
const ApplicantDetail = lazy(() =>
  import("../pages/AddJobPosition/ApplicantDetail")
);
const ApplicantsTable = lazy(() =>
  import("../pages/AddJobPosition/ApplicantsTable")
);
const ListOfDepartments = lazy(() =>
  import("../pages/ListOfDepartments/ListOfDepartments")
);
const ListOfRoles = lazy(() => import("../pages/ListOfRoles/ListOfRoles"));
const PrequaStage2 = lazy(() =>
  import("../pages/PreQualification/PrequaStage2")
);
const PrequaStage3 = lazy(() =>
  import("../pages/PreQualification/PrequaStage3")
);
const PreQualification = lazy(() =>
  import("../pages/PreQualification/PrequalificationTable")
);
const Mail = lazy(() => import("../pages/Mail/Mail"));
const Onboarding = lazy(() => import("../pages/Onboarding/Onboarding"));
const ArchivedMessages = lazy(() => import("../pages/Mail/ArchivedMessages"));
const EmployeesToOnboard = lazy(() =>
  import("../components/Onboarding/EmployeesForOnboarding/EmployeesToOnboard")
);
const CoachingMentorship = lazy(() =>
  import("../components/Onboarding/EmployeesForOnboarding/CoachingMentorship")
);
const BIForms = lazy(() => import("../pages/RSP/BIForms"));
const BIFormsSetup = lazy(() => import("../pages/RSP/BIFormsSetup"));
const BIReport = lazy(() => import("../pages/RSP/BIReport"));
const BIResults = lazy(() => import("../pages/RSP/BIResults"));
const PTForApplicants = lazy(() => import("../pages/RSP/PTForApplicants"));
const PTResults = lazy(() => import("../pages/RSP/PTResults"));
const Simulations = lazy(() => import("../pages/RSP/Simulations"));
const SimulationResults = lazy(() => import("../pages/RSP/SimulationResults"));
const EditBIForms = lazy(() => import("../pages/RSP/EditBIForms"));
const EditBIReport = lazy(() => import("../pages/RSP/EditBIReport"));
const EditSimulations = lazy(() => import("../pages/RSP/EditSimulations"));
const RSPApplicantsProfile = lazy(() =>
  import("../pages/RSP/RSPApplicantsProfile")
);
const JobExit = lazy(() => import("../pages/RSP/JobExit"));
const ViewAwards = lazy(() => import("../pages/R & R/ViewAwards"));
const SummaryOfScores = lazy(() => import("../pages/R & R/SummaryOfScores"));
const AddJobPosition = lazy(() =>
  import("../pages/AddJobPosition/AddJobPosition")
);
const ListOfScoreTemplates = lazy(() =>
  import("../pages/AddJobPosition/ListOfScoreTemplates")
);
const OnboardingSetup = lazy(() =>
  import("../components/Onboarding/Setup/OnboardingSetup")
);
const UserLevelRestriction = lazy(() =>
  import("../pages/Profile/UserLevelRestriction/UserLevelRestriction")
);
const LeaveApplied = lazy(() => import("../pages/Tables/LeaveApplied"));
const TotalEmployees = lazy(() => import("../pages/Tables/TotalEmployees"));
const Travel_Orders = lazy(() => import("../pages/Tables/Travel_Orders"));
const ListOfUser = lazy(() => import("../pages/Profile/ListOfUser/ListOfUser"));
const ChangePassword = lazy(() =>
  import("../components/ChangePassword/ChangePassword")
);
const NewVacantPosition = lazy(() =>
  import("../components/VacantPosition/NewVacantPosition")
);
const JobDescription = lazy(() =>
  import("../components/VacantPosition/JobDescription")
);
const NewAppliedPosition = lazy(() =>
  import("../components/AppliedPositions/NewAppliedPosition")
);
const AppliedJobDescription = lazy(() =>
  import("../components/AppliedPositions/AppliedJobDescription")
);
const ArchivedPositions = lazy(() =>
  import("../components/ArchivedPositions/ArchivedPositions")
);
const Form = lazy(() => import("../pages/AddJobPosition/Form"));
const EditJobPosition = lazy(() =>
  import("../pages/AddJobPosition/EditJobPosition")
);
const AddRoles = lazy(() => import("../components/Sections/AddRoles"));
const AddUser = lazy(() => import("../pages/Profile/ListOfUser/AddUser"));
const EditUser = lazy(() => import("../pages/Profile/ListOfUser/EditUser"));
const EditRoles = lazy(() =>
  import("../pages/Profile/UserLevelRestriction/EditRoles")
);
const ArchivedJobDescription = lazy(() =>
  import("../components/ArchivedPositions/ArchivedJobDescription")
);
const Trash = lazy(() => import("../Trash/Trash"));
const ComposeEmail = lazy(() =>
  import("../components/ComposeEmail/ComposeEmail")
);
const PrequaStage2Table = lazy(() =>
  import("../pages/PreQualification/PrequaStage2Table")
);
const FinalSelection = lazy(() =>
  import("../pages/PreQualification/FinalSelection")
);
const QualifiedScoring = lazy(() =>
  import("../components/QualifiedScoring/QualifiedScoring")
);
const QualifiedApplicantsTable = lazy(() =>
  import("../components/Tables/QualifiedApplicants/QualifiedApplicantsTable")
);
const PrequaStage2Template = lazy(() =>
  import("../pages/PreQualification/PrequaStage2Template")
);
const QualifiedScoringTemplate = lazy(() =>
  import("../components/QualifiedScoring/QualifiedScoringTemplate")
);
const Phase1 = lazy(() => import("../components/Onboarding/Phase 1/Phase1"));
const Phase2 = lazy(() => import("../components/Onboarding/Phase 2/Phase2"));
const Phase3 = lazy(() => import("../components/Onboarding/Phase 3/Phase3"));
const Phase4 = lazy(() => import("../components/Onboarding/Phase 4/Phase4"));
const Phase5 = lazy(() => import("../components/Onboarding/Phase 5/Phase5"));
const Phase6 = lazy(() => import("../components/Onboarding/Phase 6/Phase6"));
const OnboardingEmployees = lazy(() =>
  import("../components/Onboarding/EmployeesForOnboarding/OnboardingEmployees")
);
const ListOfAwards = lazy(() => import("../pages/R & R/ListOfAwards"));
const AddNewAward = lazy(() => import("../pages/R & R/AddNewAward"));
const EditAward = lazy(() => import("../pages/R & R/EditAward"));
const AwardingSchedule = lazy(() => import("../pages/R & R/AwardingSchedule"));
const AddNominees = lazy(() => import("../pages/R & R/AddNominees"));
const AddJudges = lazy(() => import("../pages/R & R/AddJudges"));
const ListOFNominees = lazy(() => import("../pages/R & R/ListOFNominees"));
const ListOfJudges = lazy(() => import("../pages/R & R/ListOfJudges"));
const EditAwardForEvent = lazy(() =>
  import("../pages/R & R/EditAwardForEvent")
);
const RNRAwardeesProfile = lazy(() =>
  import("../pages/R & R/RNRAwardeesProfile")
);
const ListOfAllJudges = lazy(() => import("../pages/R & R/ListOfAllJudges"));
const AddNewJudges = lazy(() => import("../pages/R & R/AddNewJudges"));
const AddOfficeNominees = lazy(() =>
  import("../pages/R & R/AddOfficeNominees")
);
const ListOfOfficeNominees = lazy(() =>
  import("../pages/R & R/ListOfOfficeNominees")
);
const AwardsToJudges = lazy(() => import("../pages/R & R/AwardsToJudges"));
const ListForScoreNominees = lazy(() =>
  import("../pages/R & R/ListForScoreNominees")
);
const ListForScoreOffice = lazy(() =>
  import("../pages/R & R/ListForScoreOffice")
);
const ScoreNominee = lazy(() => import("../pages/R & R/ScoreNominee"));
const ScorePerJudge = lazy(() => import("../pages/R & R/ScorePerJudge"));
const ScorePerCriteria = lazy(() => import("../pages/R & R/ScorePerCriteria"));
const SummaryPerJudge = lazy(() => import("../pages/R & R/SummaryPerJudge"));
const ScoreForEachJudge = lazy(() =>
  import("../pages/R & R/ScoreForEachJudge")
);
const ListOfLeaveApplied = lazy(() =>
  import("../pages/Leave Applied/ListOfLeaveApplied")
);
const ListOfTravelOrder = lazy(() =>
  import("../pages/Travel Order/ListOfTravelOrder")
);
const Phase1Gallery = lazy(() =>
  import("../components/Onboarding/Phase 1/Phase1Gallery")
);
const Phase2Gallery = lazy(() =>
  import("../components/Onboarding/Phase 2/Phase2Gallery")
);
const Phase2Orientation = lazy(() =>
  import("../components/Onboarding/Phase 2/Phase2Orientation")
);
const Phase3Journal = lazy(() =>
  import("../components/Onboarding/Phase 3/Phase3Journal")
);
const Phase3Gallery = lazy(() =>
  import("../components/Onboarding/Phase 3/Phase3Gallery")
);
const Phase3Report = lazy(() =>
  import("../components/Onboarding/Phase 3/Phase3Report")
);
const Phase4Gallery = lazy(() =>
  import("../components/Onboarding/Phase 4/Phase4Gallery")
);
const Phase4CoachingSession = lazy(() =>
  import("../components/Onboarding/Phase 4/Phase4CoachingSession")
);
const Phase4CoachingGallery = lazy(() =>
  import("../components/Onboarding/Phase 4/Phase4CoachingGallery")
);
const Phase4MentorshipSession = lazy(() =>
  import("../components/Onboarding/Phase 4/Phase4MentorshipSession")
);
const Phase5FomalDocument = lazy(() =>
  import("../components/Onboarding/Phase 5/Phase5FormalDocument")
);
const Phase5InfomalDocument = lazy(() =>
  import("../components/Onboarding/Phase 5/Phase5InformalDocument")
);
const Phase6Onboarding = lazy(() =>
  import("../components/Onboarding/Phase 6/Phase6Onboarding")
);
const Phase6Gallery = lazy(() =>
  import("../components/Onboarding/Phase 6/Phase6Gallery")
);
const ListOfPaySlip = lazy(() => import("../pages/Pay Slip/ListOfPaySlip"));
const AddStage2Template = lazy(() =>
  import("../pages/AddJobPosition/AddStage2Template")
);
const EditStage2Template = lazy(() =>
  import("../pages/AddJobPosition/EditStage2Template")
);
const AddConsolidateScoringTemplate = lazy(() =>
  import("../pages/AddJobPosition/AddConsolidateScoringTemplate")
);
const EditConsolidatedScoring = lazy(() =>
  import("../pages/AddJobPosition/EditConsolidatedScoring")
);
const Phase6IDP = lazy(() =>
  import("../components/Onboarding/Phase 6/Phase6IDP")
);
const Phase1Copy = lazy(() =>
  import("../components/Onboarding/Phase 1 copy/Phase1")
);
const Phase2Copy = lazy(() =>
  import("../components/Onboarding/Phase 2 copy/Phase2")
);
const Phase3Copy = lazy(() =>
  import("../components/Onboarding/Phase 3 copy/Phase3")
);
const Phase4Copy = lazy(() =>
  import("../components/Onboarding/Phase 4 copy/Phase4")
);
const Phase5Copy = lazy(() =>
  import("../components/Onboarding/Phase 5 copy/Phase5")
);
const Phase6Copy = lazy(() =>
  import("../components/Onboarding/Phase 6 copy/Phase6")
);
const Phase1GalleryCopy = lazy(() =>
  import("../components/Onboarding/Phase 1 copy/Phase1Gallery")
);
const Phase2GalleryCopy = lazy(() =>
  import("../components/Onboarding/Phase 2 copy/Phase2Gallery")
);
const Phase2OrientationCopy = lazy(() =>
  import("../components/Onboarding/Phase 2 copy/Phase2Orientation")
);
const Phase3JournalCopy = lazy(() =>
  import("../components/Onboarding/Phase 3 copy/Phase3Journal")
);
const Phase3GalleryCopy = lazy(() =>
  import("../components/Onboarding/Phase 3 copy/Phase3Gallery")
);
const Phase3ReportCopy = lazy(() =>
  import("../components/Onboarding/Phase 3 copy/Phase3Report")
);
const Phase4GalleryCopy = lazy(() =>
  import("../components/Onboarding/Phase 4 copy/Phase4Gallery")
);
const Phase4CoachingSessionCopy = lazy(() =>
  import("../components/Onboarding/Phase 4 copy/Phase4CoachingSession")
);
const Phase4MentorshipSessionCopy = lazy(() =>
  import("../components/Onboarding/Phase 4 copy/Phase4MentorshipSession")
);
const Phase5FomalDocumentCopy = lazy(() =>
  import("../components/Onboarding/Phase 5 copy/Phase5FormalDocument")
);
const Phase5InfomalDocumentCopy = lazy(() =>
  import("../components/Onboarding/Phase 5 copy/Phase5InformalDocument")
);
const Phase6OnboardingCopy = lazy(() =>
  import("../components/Onboarding/Phase 6 copy/Phase6Onboarding")
);
const Phase6IDPCopy = lazy(() =>
  import("../components/Onboarding/Phase 6 copy/Phase6IDP")
);
const Phase6GalleryCopy = lazy(() =>
  import("../components/Onboarding/Phase 6 copy/Phase6Gallery")
);
const CBP_TableCopy = lazy(() =>
  import("../components/Onboarding/CompetencyBasedProfile copy/CBP_Table")
);
const CBP_Table2Copy = lazy(() =>
  import("../components/Onboarding/CompetencyBasedProfile copy/CBP_Table2")
);
const CBP_Table3Copy = lazy(() =>
  import("../components/Onboarding/CompetencyBasedProfile copy/CBP_Table3")
);
const OOR = lazy(() =>
  import("../components/Onboarding/OverallOnboardingReport copy/OOR")
);
const Congratulatory = lazy(() =>
  import("../components/Onboarding/Cogratulatory")
);
const Phase4CoachingSessionCopy2 = lazy(() =>
  import("../components/Onboarding/Phase 4 copy 2/Phase4CoachingSession")
);
const Phase4MentorshipSessionCopy2 = lazy(() =>
  import("../components/Onboarding/Phase 4 copy 2/Phase4MentorshipSession")
);
const PsychosocialCriterias = lazy(() =>
  import("../components/Onboarding/Setup/PsychosocialCriterias")
);
const RaterForPositions = lazy(() =>
  import("../components/Onboarding/Setup/RaterForPositions")
);
const HRMPSBTable = lazy(() => import("../pages/HRMPSB/HRMPSBTable"));
const ApplicantsForHRMPSB = lazy(() =>
  import("../pages/HRMPSB/ApplicantsForHRMPSB")
);
const HRMPSBScoringRoot = lazy(() =>
  import("../pages/HRMPSB/HRMPSBScoringRoot")
);
const DefaultLayout = lazy(() => import("../layout/DefaultLayout"));

function Routers() {
  const cookies = document.cookie.split(";").reduce((prev, curr) => {
    const [name, value] = curr.trim().split("=");
    prev[name] = value;
    return prev;
  }, {});
  const login = `${window.globalConfig.BASE_URL2}/api/Login`;

  const userTasks = useSelector((state) => state.auth.userTasks)?.split(",");

  const [data, setData] = useState();

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const hasProfile = useSelector((state) => state.auth.hasProfile);
  const isJudge = useSelector((state) => state.auth.isJudge);
  const isCoachMentor = useSelector((state) => state.auth.isCoachMentor);

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    if (localStorage.getItem("RememberMe") == null) {
      localStorage.removeItem("SavedEmail");
      localStorage.removeItem("SavedPassword");
    }
  };
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setTasks(JSON.parse(cookies.Tasks));
        const response = await fetch(`${login}/CurrentUser`, {
          method: "Get",
          headers: { "Content-Type": "application/json" },
          mode: "cors",
          credentials: "include",
        });
        const data = await response.json();
        setData(data);
      } catch (error) {
        // Invalid JSON, handle the error here
      }
    };
    fetchData();
  }, []);

  const [preQualIsPresent, setPreQualIsPresent] = useState(
    userTasks?.some((item) => item === "33C2EDA2-73AD-438E-B79A-7A9622DDE5F4")
  );
  const [addPreQualIsPresent, setAddPreQualIsPresent] = useState(
    userTasks?.some((item) => item === "758A10F7-AFBC-4D36-81EA-D9D48C9ADD43")
  );
  const [editPreQualIsPresent, setEditPreQualIsPresent] = useState(
    userTasks?.some((item) => item === "905A976B-4375-492E-BB51-EEC1976C3F2C")
  );
  const [rolesIsPresent, setRolesIsPresent] = useState(
    userTasks?.some((item) => item === "ACA50AA8-3666-49DD-9B61-F0E146B23663")
  );
  const [addRolesIsPresent, setAddRolesIsPresent] = useState(
    userTasks?.some((item) => item === "D01F669C-C686-46F2-8FA3-EB9B9868EA3C")
  );
  const [editRolesIsPresent, setEditRolesIsPresent] = useState(
    userTasks?.some((item) => item === "775B59AB-183C-48F5-B93E-734BCBF24780")
  );
  const [usersIsPresent, setUsersIsPresent] = useState(
    userTasks?.some((item) => item === "84253EEA-122E-471E-B117-7A46988192CB")
  );
  const [addUsersIsPresent, setAddUsersIsPresent] = useState(
    userTasks?.some((item) => item === "9A03BEBB-AF20-40F6-87D4-C6754A89C027")
  );
  const [editUsersIsPresent, setEditUsersIsPresent] = useState(
    userTasks?.some((item) => item === "60FFC821-F1DE-4F89-83AD-DBB3D4241529")
  );
  const [deletePreQualIsPresent, setDeletePreQualIsPresent] = useState(
    userTasks?.some((item) => item === "41555934-4071-4DAB-B144-E6A56CAEB440")
  );
  const [onboardingIsPresent, setOnboardingIsPresent] = useState(
    userTasks?.some((item) => item === "1F6F3A3F-9731-4670-AF7A-4BC1B0475F95")
  );
  const [approvedonboardingIsPresent, setApprovedOnboardingIsPresent] =
    useState(
      userTasks?.some((item) => item === "C091F9F7-23D4-4C5D-B920-1E8D82C2AC82")
    );
  const [returnonboardingIsPresent, setReturnOnboardingIsPresent] = useState(
    userTasks?.some((item) => item === "F3750B1A-9B65-42F2-97DF-10B24996F8F0")
  );
  const [rejectonboardingIsPresent, setRejectOnboardingIsPresent] = useState(
    userTasks?.some((item) => item === "6ED786DD-FF04-4DE3-8E94-B2FA80BDD7A0")
  );
  const [setDatenoboardinglIsPresent, setSetDateOnboardingIsPresent] = useState(
    userTasks?.some((item) => item === "5D0D2CF7-5518-4F00-9A18-8BDA974FC521")
  );
  const [viewConsolidatedIsPresent, setViewConsolidatedIsPresent] = useState(
    userTasks?.some((item) => item === "00000000-0000-0000-0000-000000000001")
  );

  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const isEmployee = useSelector((state) => state.auth.isEmployee);

  useEffect(() => {
    // if (userTasks === undefined) {
    // } else {
    const json = JSON.stringify(userTasks);
    setPreQualIsPresent(
      userTasks?.some((item) => item === "33C2EDA2-73AD-438E-B79A-7A9622DDE5F4")
    );
    setAddPreQualIsPresent(
      userTasks?.some((item) => item === "758A10F7-AFBC-4D36-81EA-D9D48C9ADD43")
    );
    setEditPreQualIsPresent(
      userTasks?.some((item) => item === "905A976B-4375-492E-BB51-EEC1976C3F2C")
    );
    setRolesIsPresent(
      userTasks?.some((item) => item === "ACA50AA8-3666-49DD-9B61-F0E146B23663")
    );
    setEditRolesIsPresent(
      userTasks?.some((item) => item === "775B59AB-183C-48F5-B93E-734BCBF24780")
    );
    setAddRolesIsPresent(
      userTasks?.some((item) => item === "D01F669C-C686-46F2-8FA3-EB9B9868EA3C")
    );
    setUsersIsPresent(
      userTasks?.some((item) => item === "84253EEA-122E-471E-B117-7A46988192CB")
    );
    setAddUsersIsPresent(
      userTasks?.some((item) => item === "9A03BEBB-AF20-40F6-87D4-C6754A89C027")
    );
    setEditUsersIsPresent(
      userTasks?.some((item) => item === "60FFC821-F1DE-4F89-83AD-DBB3D4241529")
    );
    setDeletePreQualIsPresent(
      userTasks?.some((item) => item === "41555934-4071-4DAB-B144-E6A56CAEB440")
    );
    setOnboardingIsPresent(
      userTasks?.some((item) => item === "1F6F3A3F-9731-4670-AF7A-4BC1B0475F95")
    );
    setApprovedOnboardingIsPresent(
      userTasks?.some((item) => item === "C091F9F7-23D4-4C5D-B920-1E8D82C2AC82")
    );
    setReturnOnboardingIsPresent(
      userTasks?.some((item) => item === "F3750B1A-9B65-42F2-97DF-10B24996F8F0")
    );
    setRejectOnboardingIsPresent(
      userTasks?.some((item) => item === "6ED786DD-FF04-4DE3-8E94-B2FA80BDD7A0")
    );
    setSetDateOnboardingIsPresent(
      userTasks?.some((item) => item === "5D0D2CF7-5518-4F00-9A18-8BDA974FC521")
    );
    setViewConsolidatedIsPresent(
      userTasks?.some((item) => item === "00000000-0000-0000-0000-000000000001")
    );
    // document.cookie = `Tasks=${json}; expires=0; path=/;`;
    // }

    if (
      userTasks?.length > 0 &&
      userTasks[0] !== "null" &&
      userTasks[0] !== ""
    ) {
      if (
        userTasks?.some(
          (item) => item === "33C2EDA2-73AD-438E-B79A-7A9622DDE5F4"
        ) &&
        userTasks?.length < 3
      ) {
        document.cookie = `isAdmin=false; expires=0; path=/;`;
      } else {
        document.cookie = `isAdmin=true; expires=0; path=/;`;
      }
    } else if (userTasks === undefined) {
    } else {
      document.cookie = `isAdmin=false; expires=0; path=/;`;
    }
  }, [userTasks]);

  return (
    <>
      <HashRouter>
        <Suspense
          fallback={
            <div className="bg-transparent ">
              <div class="absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 ">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-blue-800 border-opacity-100"></div>
              </div>
            </div>
          }
        >
          <Routes>
            {/* Default Route */}
            <Route
              path="/"
              element={
                !userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-EEEE-000000000001"
                ) ? (
                  <Home />
                ) : (
                  <Navigate to="/hiring-dashboard" />
                )
              }
            />
            <Route
              path="/login"
              element={
                !isLoggedIn ? (
                  <Login />
                ) : isAdmin ? (
                  <Navigate to="/hiring-dashboard" />
                ) : hasProfile ? (
                  <Navigate to="/dashboard" />
                ) : (
                  <Navigate to="/personal-data-sheet-form" />
                )
              }
            />
            <Route
              path="/"
              element={isLoggedIn ? <Home /> : <Navigate to="/login" />}
            />
            {/* <Route
            path="/admin-dashboard"
            element={
              isAdmin && isLoggedIn ? (
                <DefaultLayout>
                  <AdminDashboard />
                </DefaultLayout>
              ) : isLoggedIn ? (
                <Navigate to="/dashboard" />
              ) : (
                <Navigate to="/login" />
              )
            }
          /> */}
            <Route
              path="/hiring-dashboard"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-EEEE-000000000001"
                ) && isLoggedIn ? (
                  <DefaultLayout>
                    <HiringDashboard />
                  </DefaultLayout>
                ) : isLoggedIn ? (
                  <Navigate to="/dashboard" />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/qualified-applicants-table"
              element={
                isAdmin && isLoggedIn ? (
                  <DefaultLayout>
                    <QualifiedApplicantsTable />
                  </DefaultLayout>
                ) : isLoggedIn ? (
                  <Navigate to="/dashboard" />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            {/* Dashboard */}
            <Route
              path="/dashboard"
              element={
                isLoggedIn ? (
                  <DefaultLayout>
                    <Dashboard />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            {/* <Route path="/login" element={<Login />} />
          <Route path="/hiring-dashboard" element={<HiringDashboard />} />
          <Route
          path="/admin-dashboard"
          element={
            isAdmin === true ? (
              <AdminDashboard />
              ) : (
                <Navigate to="/dashboard" />
              )
            }
          />
          {isLoggedIn ? (
            <Route path="/" element={<Home />} />
            ) : (
              <Route path="/login" element={<Login />} />
              )}
            <Route path="/login" element={<Login />} /> */}
            {/* Auth */}
            <Route
              path="/signup"
              element={
                !isLoggedIn ? (
                  <NewSignup />
                ) : isAdmin ? (
                  <Navigate to="/hiring-dashboard" />
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/signup-verification"
              element={
                !isLoggedIn ? (
                  <NewSignupVerification />
                ) : isAdmin ? (
                  <Navigate to="/hiring-dashboard" />
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/forgot-password"
              element={
                !isLoggedIn ? (
                  <NewForgotPassword />
                ) : isAdmin ? (
                  <Navigate to="/hiring-dashboard" />
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/resetpassword"
              element={
                !isLoggedIn ? (
                  <NewResetPassword />
                ) : isAdmin ? (
                  <Navigate to="/hiring-dashboard" />
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/change-password"
              element={
                !isLoggedIn ? (
                  <ChangePassword />
                ) : isAdmin ? (
                  <Navigate to="/hiring-dashboard" />
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/email-verification"
              element={
                !isLoggedIn ? (
                  <EmailVerification />
                ) : isAdmin ? (
                  <Navigate to="/hiring-dashboard" />
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            {/* Admin Dropdown */}
            <Route
              path="/list-of-user"
              element={
                usersIsPresent === true ? (
                  <DefaultLayout>
                    <ListOfUser />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/user-level-restrictions"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-CCCC-000000000011"
                ) ? (
                  <DefaultLayout>
                    <UserLevelRestriction />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            {/* Datas */}
            <Route
              path="/total-employees"
              element={
                isAdmin === true ? (
                  <DefaultLayout>
                    <TotalEmployees />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/leave-applied"
              element={
                isAdmin === true ? (
                  <DefaultLayout>
                    <LeaveApplied />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/travel-orders"
              element={
                isAdmin === true ? (
                  <DefaultLayout>
                    <Travel_Orders />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            {/* Onboarding */}
            <Route
              path="/phase-1-pre-employment-preliminaries/:id"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase1 />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-1-pre-employment-preliminaries:-gallery/:id"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase1Gallery />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-2-onboarding-at-office-of-assignment/:id"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase2 />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-2-onboarding-at-office-of-assignment:-orientation-evaluation/:id"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase2Orientation />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-2-onboarding-at-office-of-assignment:-gallery/:id"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase2Gallery />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-3-evaluation-(First-6-months)/:id"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase3 />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-3-evaluation-(First-6-months):-journal/:id"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase3Journal />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-3-evaluation-(First-6-months):-gallery/:id"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase3Gallery />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-3-evaluation-(First-6-months):-report/:id"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase3Report />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-4-evaluation-(Last-6-months)/:id"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase4 />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-4-evaluation-(Last-6-months):-gallery/:id"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase4Gallery />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-4-evaluation-(Last-6-months):-coaching-session/:id"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase4CoachingSession />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-4-evaluation-(Last-6-months):-coaching-gallery/:id"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase4CoachingGallery />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-4-evaluation-(Last-6-months):-coaching-photo-documentation/:id"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase4CoachingGallery />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-4-evaluation-(Last-6-months):-mentorship-session/:id"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase4MentorshipSession />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-5-discovery-phase/:id"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase5 />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-5-discovery-phase:-formal-training-document/:id"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase5FomalDocument />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-5-discovery-phase:-informal-training-document/:id"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase5InfomalDocument />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-6-engagement-phase/:id"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase6 />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-6-engagement-phase:-onboarding-feedback/:id"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase6Onboarding />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-6-engagement-phase:-gallery/:id"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase6Gallery />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-6-engagement-phase:-individual-delopment-plan/:id"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase6IDP />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            {/* <Route
            path="/competency-based-profile:-core-competency/:id"
            element={
              isEmployee === true ? (
                <DefaultLayout>
                  <CBP_Table />
                </DefaultLayout>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/competency-based-profile:-functional-competency/:id"
            element={
              isEmployee === true ? (
                <DefaultLayout>
                  <CBP_Table2 />
                </DefaultLayout>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/competency-based-profile:-leadership-competency/:id"
            element={
              isEmployee === true ? (
                <DefaultLayout>
                  <CBP_Table3 />
                </DefaultLayout>
              ) : (
                <Navigate to="/login" />
              )
            }
          /> */}
            <Route
              path="/phase-1-pre-employment-preliminaries-for/:id/:id2"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase1Copy />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-1-pre-employment-preliminaries:-gallery-for/:id/:id2"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase1GalleryCopy />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-2-onboarding-at-office-of-assignment-for/:id/:id2"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase2Copy />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-2-onboarding-at-office-of-assignment:-orientation-evaluation-for/:id/:id2"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase2OrientationCopy />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-2-onboarding-at-office-of-assignment:-gallery-for/:id/:id2"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase2GalleryCopy />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-3-evaluation-(First-6-months)-for/:id/:id2"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase3Copy />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-3-evaluation-(First-6-months):-journal-for/:id/:id2"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase3JournalCopy />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-3-evaluation-(First-6-months):-gallery-for/:id/:id2"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase3GalleryCopy />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-3-evaluation-(First-6-months):-report-for/:id/:id2"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase3ReportCopy />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-4-evaluation-(Last-6-months)-for/:id/:id2"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase4Copy />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-4-evaluation-(Last-6-months):-gallery-for/:id/:id2"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase4GalleryCopy />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-4-evaluation-(Last-6-months):-coaching-session-for/:id/:id2"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase4CoachingSessionCopy />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-4-evaluation-(Last-6-months):-mentorship-session-for/:id/:id2"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase4MentorshipSessionCopy />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-5-discovery-phase-for/:id/:id2"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase5Copy />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-5-discovery-phase:-formal-training-document-for/:id/:id2"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase5FomalDocumentCopy />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-5-discovery-phase:-informal-training-document-for/:id/:id2"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase5InfomalDocumentCopy />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-6-engagement-phase-for/:id/:id2"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase6Copy />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-6-engagement-phase:-onboarding-feedback-for/:id/:id2"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase6OnboardingCopy />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-6-engagement-phase:-gallery-for/:id/:id2"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase6GalleryCopy />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-6-engagement-phase:-individual-delopment-plan-for/:id/:id2"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase6IDPCopy />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/overall-onboarding-report-for/:id/:id2"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <OOR />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/congratulatory-letter-for/:id/:id2"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Congratulatory />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/competency-based-profile:-core-competency-for/:id/:id2"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <CBP_TableCopy />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/competency-based-profile:-functional-competency-for/:id/:id2"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <CBP_Table2Copy />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/competency-based-profile:-leadership-competency-for/:id/:id2"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <CBP_Table3Copy />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-4-evaluation-(Last-6-months):-coaching/:id/:id2"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase4CoachingSessionCopy2 />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/phase-4-evaluation-(Last-6-months):-mentoring/:id/:id2"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Phase4MentorshipSessionCopy2 />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/onboarding-setup"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-CCCC-000000000003"
                ) ? (
                  <DefaultLayout>
                    <OnboardingSetup />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/onboarding"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Onboarding />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/employees-for-onboarding"
              element={
                onboardingIsPresent === true ? (
                  <DefaultLayout>
                    <EmployeesToOnboard />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/coaching-and-mentorship"
              element={
                isCoachMentor === true ? (
                  <DefaultLayout>
                    <CoachingMentorship />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/adding-to-onboarding"
              element={
                onboardingIsPresent === true ? (
                  <DefaultLayout>
                    <OnboardingEmployees />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            {/* R&R */}
            <Route
              path="/list-of-awards"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-CCCC-000000000006"
                ) ? (
                  <DefaultLayout>
                    <ListOfAwards />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/add-new-award"
              element={
                isAdmin === true ? (
                  <DefaultLayout>
                    <AddNewAward />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/edit-award/:id"
              element={
                isAdmin === true ? (
                  <DefaultLayout>
                    <EditAward />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/awarding-schedule"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-CCCC-000000000007"
                ) ? (
                  <DefaultLayout>
                    <AwardingSchedule />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/nomination"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-BBBB-000000000001"
                ) ? (
                  <DefaultLayout>
                    <ViewAwards />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            {/* <Route
            path="/nomination-for-awards/:id"
            element={
              isAdmin === true ? (
                <DefaultLayout>
                  <NominationOfEmployees />
                </DefaultLayout>
              ) : (
                <Navigate to="/login" />
              )
            }
          /> */}
            <Route
              path="/edit-event-award/:id/"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-BBBB-000000000001"
                ) ? (
                  <DefaultLayout>
                    <EditAwardForEvent />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/list-of-nominees/:id"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-BBBB-000000000001"
                ) ? (
                  <DefaultLayout>
                    <ListOFNominees />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/add-nominees/:id"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-BBBB-000000000001"
                ) ? (
                  <DefaultLayout>
                    <AddNominees />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/list-of-office-nominees/:id"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-BBBB-000000000001"
                ) ? (
                  <DefaultLayout>
                    <ListOfOfficeNominees />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/add-office-nominees/:id"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-BBBB-000000000001"
                ) ? (
                  <DefaultLayout>
                    <AddOfficeNominees />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/list-of-judges/:id"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-BBBB-000000000001"
                ) ? (
                  <DefaultLayout>
                    <ListOfJudges />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/judge-score/:id/:id2"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-BBBB-000000000001"
                ) ? (
                  <DefaultLayout>
                    <ScoreForEachJudge />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/add-judges/:id/:id2/:id3"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-BBBB-000000000001"
                ) ? (
                  <DefaultLayout>
                    <AddJudges />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/PRAISE-committee"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-CCCC-000000000008"
                ) ? (
                  <DefaultLayout>
                    <ListOfAllJudges />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/add-new-judge"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-CCCC-000000000008"
                ) ? (
                  <DefaultLayout>
                    <AddNewJudges />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/R&R-awardees-profile"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-BBBB-000000000002"
                ) ? (
                  <DefaultLayout>
                    <RNRAwardeesProfile />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/judges'-ratings"
              element={
                isJudge === true ? (
                  <DefaultLayout>
                    <AwardsToJudges />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/score-for-nominees/:id"
              element={
                isJudge === true ? (
                  <DefaultLayout>
                    <ListForScoreNominees />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/nominee-score/:id/:id2"
              element={
                isJudge === true ? (
                  <DefaultLayout>
                    <ScoreNominee />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/score-for-office-nominees/:id"
              element={
                isJudge === true ? (
                  <DefaultLayout>
                    <ListForScoreOffice />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/summary"
              element={
                isJudge === true || isAdmin === true ? (
                  <DefaultLayout>
                    <SummaryOfScores />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/summary-of-scores/:id"
              element={
                isJudge === true || isAdmin === true ? (
                  <DefaultLayout>
                    <ScorePerJudge />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/scores-per-criteria/:id"
              element={
                isJudge === true || isAdmin === true ? (
                  <DefaultLayout>
                    <ScorePerCriteria />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/summary-of-judge-score/:id/:id2"
              element={
                isJudge === true || isAdmin === true ? (
                  <DefaultLayout>
                    <SummaryPerJudge />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            {/* Leave Applied */}
            <Route
              path="/leave-application"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <ListOfLeaveApplied />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />{" "}
            {/* Travel Order */}
            <Route
              path="/travel-order"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <ListOfTravelOrder />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            {/* Pay Slip */}
            <Route
              path="/pass-slip"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <ListOfPaySlip />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            {/* Mail */}
            <Route
              path="/messages"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <Mail />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/archived-messages"
              element={
                isEmployee === true ? (
                  <DefaultLayout>
                    <ArchivedMessages />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            {/* Form */}
            <Route
              path="/personal-data-sheet-form"
              element={
                isLoggedIn ? (
                  <DefaultLayout>
                    <Profile />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/vacant-position/"
              element={
                isLoggedIn ? (
                  <DefaultLayout>
                    <NewVacantPosition />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/vacant-position/:id"
              element={
                <DefaultLayout>
                  <JobDescription />
                </DefaultLayout>
              }
            />
            <Route
              path="/applied-position/"
              element={
                isLoggedIn ? (
                  <DefaultLayout>
                    <NewAppliedPosition />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/applied-position/:id"
              element={
                isLoggedIn ? (
                  <DefaultLayout>
                    <AppliedJobDescription />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/archived-position"
              element={
                isLoggedIn ? (
                  <DefaultLayout>
                    <ArchivedPositions />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/archived-position/:id"
              element={
                isLoggedIn ? (
                  <DefaultLayout>
                    <ArchivedJobDescription />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/Job-Positions"
              element={
                // addPreQualIsPresent ||
                // editPreQualIsPresent ||
                // deletePreQualIsPresent
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-CCCC-000000000001"
                ) ? (
                  <DefaultLayout>
                    <AddJobPosition />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/psychosocial-criterias"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-CCCC-000000000005"
                ) ? (
                  <DefaultLayout>
                    <PsychosocialCriterias />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/raters-for-positions"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-CCCC-000000000004"
                ) ? (
                  <DefaultLayout>
                    <RaterForPositions />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/score-templates"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-CCCC-000000000002"
                ) ? (
                  <DefaultLayout>
                    <ListOfScoreTemplates />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/add-stage-2-template"
              element={
                isAdmin ? (
                  <DefaultLayout>
                    <AddStage2Template />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/edit-stage-2-template/:id"
              element={
                isAdmin ? (
                  <DefaultLayout>
                    <EditStage2Template />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/add-consolidated-scoring-template"
              element={
                isAdmin ? (
                  <DefaultLayout>
                    <AddConsolidateScoringTemplate />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/edit-consolidated-scoring-template/:id"
              element={
                isAdmin ? (
                  <DefaultLayout>
                    <EditConsolidatedScoring />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/job-position-form"
              element={
                addPreQualIsPresent === true ? (
                  <DefaultLayout>
                    <Form />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/edit-job-position/:id"
              element={
                editPreQualIsPresent === true ? (
                  <DefaultLayout>
                    <EditJobPosition />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/delisting-stage-1/:id"
              element={
                // addPreQualIsPresent ||
                // editPreQualIsPresent ||
                // deletePreQualIsPresent
                userTasks?.some(
                  (item) => item === "00000000-0000-0000-0000-000000000004"
                ) ? (
                  <DefaultLayout>
                    <ApplicantsTable />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/delisting-stage-1:-initial-screening/:id/:id2"
              element={
                // addPreQualIsPresent ||
                // editPreQualIsPresent ||
                // deletePreQualIsPresent === true
                userTasks?.some(
                  (item) => item === "00000000-0000-0000-0000-000000000004"
                ) ? (
                  <DefaultLayout>
                    <ApplicantDetail />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/prequalification-table"
              element={
                preQualIsPresent === true && isAdmin ? (
                  <DefaultLayout>
                    <PreQualification />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/Prequalification-Stage-2-Scoring/:id/:id2"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-0000-0000-000000000005"
                ) ? (
                  <DefaultLayout>
                    <PrequaStage2 />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/Qualified-Applicants-Scoring/:id/:id2"
              element={
                // preQualIsPresent === true && isAdmin
                viewConsolidatedIsPresent ? (
                  <DefaultLayout>
                    <QualifiedScoring />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/Prequalification-Stage-2/:id"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-0000-0000-000000000005"
                ) ? (
                  <DefaultLayout>
                    <PrequaStage2Table />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/Prequalification-Stage-2-Template/:id"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-0000-0000-000000000005"
                ) ? (
                  <DefaultLayout>
                    <PrequaStage2Template />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/consolidated-scoring/:id"
              element={
                // preQualIsPresent === true &&
                // isAdmin &&
                viewConsolidatedIsPresent ? (
                  <DefaultLayout>
                    <PrequaStage3 />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/Qualified-Applicants-Template/:id"
              element={
                // preQualIsPresent === true && isAdmin
                viewConsolidatedIsPresent ? (
                  <DefaultLayout>
                    <QualifiedScoringTemplate />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/final-selection/:id"
              element={
                preQualIsPresent === true && isAdmin ? (
                  <DefaultLayout>
                    <FinalSelection />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/HRMPSB-table"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-AAAA-000000000001"
                ) ? (
                  <DefaultLayout>
                    <HRMPSBTable />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/HRMPSB-applicants/:id"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-AAAA-000000000001"
                ) ? (
                  <DefaultLayout>
                    <ApplicantsForHRMPSB />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/HRMPSB-scoring/:id/:id2"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-AAAA-000000000001"
                ) ? (
                  <DefaultLayout>
                    <HRMPSBScoringRoot />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/background-investigation-form-setup"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-AAAA-000000000002"
                ) ? (
                  <DefaultLayout>
                    <BIFormsSetup />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/background-investigation-form"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-AAAA-000000000002"
                ) ? (
                  <DefaultLayout>
                    <BIForms />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/edit-background-investigation-form/:id"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-AAAA-000000000004"
                ) ? (
                  <DefaultLayout>
                    <EditBIForms />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/edit-background-investigation-report/:id"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-AAAA-000000000004"
                ) ? (
                  <DefaultLayout>
                    <EditBIReport />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/background-investigation-report"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-AAAA-000000000003"
                ) ? (
                  <DefaultLayout>
                    <BIReport />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/background-investigation-results"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-AAAA-000000000004"
                ) ? (
                  <DefaultLayout>
                    <BIResults />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/pen-test"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-AAAA-000000000005"
                ) ? (
                  <DefaultLayout>
                    <PTForApplicants />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/pen-test-results"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-AAAA-000000000006"
                ) ? (
                  <DefaultLayout>
                    <PTResults />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/simulations-performance-sheet"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-AAAA-000000000007"
                ) ? (
                  <DefaultLayout>
                    <Simulations />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/edit-simulations-performance-sheet/:id"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-AAAA-000000000008"
                ) ? (
                  <DefaultLayout>
                    <EditSimulations />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/simulations-performance-results"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-AAAA-000000000008"
                ) ? (
                  <DefaultLayout>
                    <SimulationResults />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/rsp-applicants-profile"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-AAAA-000000000009"
                ) ? (
                  <DefaultLayout>
                    <RSPApplicantsProfile />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/job-exit-interview-questions"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-AAAA-000000000010"
                ) ? (
                  <DefaultLayout>
                    <JobExit />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/list-of-roles"
              element={
                rolesIsPresent === true ? (
                  <DefaultLayout>
                    <ListOfRoles />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/add-roles"
              element={
                addRolesIsPresent === true ? (
                  <DefaultLayout>
                    <AddRoles />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/edit-roles/:id"
              element={
                editRolesIsPresent === true ? (
                  <DefaultLayout>
                    <EditRoles />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/list-of-departments"
              element={
                isAdmin === true ? (
                  <DefaultLayout>
                    <ListOfDepartments />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/add-departments"
              element={
                addRolesIsPresent === true ? (
                  <DefaultLayout>
                    <AddRoles />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/edit-departments/:id"
              element={
                editRolesIsPresent === true ? (
                  <DefaultLayout>
                    <EditRoles />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/add-user"
              element={
                addUsersIsPresent === true ? (
                  <DefaultLayout>
                    <AddUser />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/edit-user/:id"
              element={
                editUsersIsPresent === true ? (
                  <DefaultLayout>
                    <EditUser />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/personal-data-sheet"
              element={
                <DefaultLayout>
                  <SavedProfile />
                </DefaultLayout>
              }
            />
            <Route
              path="/profile-setting"
              element={
                <DefaultLayout>
                  <AccountSetting />
                </DefaultLayout>
              }
            />
            <Route
              path="/recycle-bin"
              element={
                userTasks?.some(
                  (item) => item === "00000000-0000-AAAA-DDDD-000000000001"
                ) ? (
                  <DefaultLayout>
                    <Trash />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/Compose-Email"
              element={
                isAdmin === true ? (
                  <DefaultLayout>
                    <ComposeEmail />
                  </DefaultLayout>
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/Page-Under-Construction"
              element={
                <DefaultLayout>
                  <PageUnderConstruction />
                </DefaultLayout>
              }
            />
            <Route path="/Page-Not-Found" element={<WrongPage />} />
            <Route
              path="*"
              element={<Navigate to="/Page-Not-Found" replace={true} />}
            />
          </Routes>
        </Suspense>
      </HashRouter>
    </>
  );
}

export default Routers;
